declare global {
  interface Window {
    gtm: (
      option: string,
      gaTrackingId: string,
      options: Record<string, unknown>
    ) => void;
  }
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtmjs/pages
 */
export const pageview = (url: string, trackingId: string) => {
  if (!window.gtm) {
    console.warn(
      "window.gtm is not defined. This could mean your google analytics script has not loaded on the page yet."
    );
    return;
  }
  window.gtm("config", trackingId, {
    page_path: url
  });
};

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtmjs/events
 */
export const event = ({
  action,
  category,
  label,
  value
}: Record<string, string>) => {
  if (!window.gtm) {
    console.warn(
      "window.gtm is not defined. This could mean your google analytics script has not loaded on the page yet."
    );
    return;
  }
  window.gtm("event", action, {
    event_category: category,
    event_label: label,
    value: value
  });
};
